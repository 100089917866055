<template>
    <div class="card col-lg-12 p-0">
        <div class="card-body">
            <h3 class="font-600 mb-2">{{$route.params.id ? 'Edit' : 'Add'}} Flow</h3>
            <div class="alert-danger p-1 mb-1" style="border-radius: 8px;" v-if="errors">
                <ul class="mb-0">
                    <div v-for="(v, k) in errors" :key="k">
                        <li class="text-danger">{{v.message}}</li>
                    </div>
                </ul>
            </div>
            <ValidationObserver class="card-content" ref="observer">
                <form @submit.prevent="save()">
                    <ValidationProvider name="title" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Title</label>
                            <input type="text" v-model.lazy="model.title" class="form-control" placeholder="Enter title">
                            <small class="val-error" v-if="errors[0] && !uniqueError.name">{{ errors[0] }}</small>
							<small class="text-danger" v-else-if="uniqueError.name">Flow title has been used</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="description" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Description</label>
                            <textarea name="" id="" cols="30" rows="5" v-model="model.description" class="form-control" placeholder="Enter description"></textarea>
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="polyclinic" v-slot="{ errors }" rules="required">
                        <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                            <label class="required">Polyclinic</label>
                            <multiselect 
                                v-model="selectedPolyclinic"
                                label="namePage"
                                track-by="id" 
                                placeholder="Choose Polyclinic"
                                :options="polyclinic" 
                                :allow-empty="false"
                                @input="
                                    model.polyClinicId = selectedPolyclinic.id, 
                                    model.price = parseInt(selectedPolyclinic.priceAssesor), 
                                    model.discountPrice = 0,
                                    model.reactivationPrice = parseInt(selectedPolyclinic.priceAssesor), 
                                    model.reactivationDiscountPrice = 0">
                                <span slot="noResult">Oops! Data not found.</span>
                            </multiselect>

                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <div class="row">
                        <ValidationProvider name="Consultation Retail Price" v-slot="{ errors }" :rules="'required|min_value:' + parseInt(selectedPolyclinic.priceAssesor)" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Consultation Retail Price</label>
                                <input type="number" @keypress="numberOnly" v-model="model.price" class="form-control" placeholder="Enter consultation retail price" :disabled="!selectedPolyclinic" />
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Consultation Selling Price" v-slot="{ errors }" :rules="{ min_value: 0, max_value: model.price == 0 ? false : model.price - 1 }" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Consultation Selling Price</label>
                                <input type="number" @keypress="numberOnly" v-model="model.discountPrice" class="form-control" placeholder="Enter consultation selling price" :disabled="!selectedPolyclinic || model.price == 0" />
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="row">
                        <ValidationProvider name="Reactivation Retail Price" v-slot="{ errors }" :rules="'required|min_value:' + parseInt(selectedPolyclinic.priceAssesor)" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Reactivation Retail Price</label>
                                <input type="number" @keypress="numberOnly" v-model="model.reactivationPrice" class="form-control" placeholder="Enter reactivation retail price" :disabled="!selectedPolyclinic" />
                                <small class="text-muted" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider name="Reactivation Selling Price" v-slot="{ errors }" :rules="{ min_value: 0, max_value: model.reactivationPrice == 0 ? false : model.reactivationPrice - 1}" class="col">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="">Reactivation Selling Price</label>
                                <input type="number" @keypress="numberOnly" v-model="model.reactivationDiscountPrice" class="form-control" placeholder="Enter reactivation selling price" :disabled="!selectedPolyclinic || model.reactivationPrice == 0 " />
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                    </div>
                    <h3 class="font-600 mb-2">Contents</h3>
                    <section v-for="(page, idx) in model.items" :key="idx">
                        <ValidationProvider :name="'type '+(idx+1)" v-slot="{ errors }" rules="required">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Type</label>
                                <select class="form-control" v-model="model.items[idx].type" @change="selectedContents[idx] = null">
                                    <option value="">Choose Type</option>
                                    <option value="questionnaire">Questionnaire</option>
                                    <option value="treatment">Treatment</option>
                                </select>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider :name="'content '+(idx+1)" v-slot="{ errors }" rules="required">
                            <div class="form-group " :class="{ 'validation-warning': errors[0] }">
                                <label class="required">Content</label>
                                <!-- <select class="form-control" v-model="model.items[idx].id" v-if="model.items[idx].type == 'questionnaire'">
                                    <option value="">Choose Content</option>
                                    <option v-for="(questionnaire, index) in option.questionnaires" :key="index" :value="questionnaire.id">{{questionnaire.title}}</option>
                                </select> -->
                                <multiselect v-if="model.items[idx].type == 'questionnaire'"
                                    v-model="selectedContents[idx]"
                                    label="title"
                                    track-by="id" 
                                    placeholder="Choose Content"
                                    :options="option.questionnaires" 
                                    :allow-empty="false"
                                    @input="model.items[idx].id = selectedContents[idx].id">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <!-- <select class="form-control" v-model="model.items[idx].id" v-else-if="model.items[idx].type == 'treatment'">
                                    <option value="">Choose Content</option>
                                    <option v-for="(treatment, index) in option.treatments" :key="index" :value="treatment.id">{{treatment.title}}</option>
                                </select> -->
                                <multiselect v-else-if="model.items[idx].type == 'treatment'"
                                    v-model="selectedContents[idx]"
                                    label="title"
                                    track-by="id" 
                                    placeholder="Choose Content"
                                    :options="option.treatments" 
                                    :allow-empty="false"
                                    @input="model.items[idx].id = selectedContents[idx].id">
                                    <span slot="noResult">Oops! Data not found.</span>
                                </multiselect>
                                <select name="" id="" class="form-control" disabled v-else>
                                    <option value="">Choose Content</option>
                                </select>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </ValidationProvider>
                        <button type="button" class="btn btn-danger" @click="deleteContent(idx)" v-if="model.items.length > 1"><i class="fa fa-trash"></i> Delete Content</button>&nbsp;
                        <button type="button" class="btn btn-primary" @click="addContent()" v-if="idx == model.items.length-1"><i class="fa fa-plus"></i> Add Content</button>
                        <hr>
                    </section>
                    <div class=" mt-1">
                        <router-link to="/flows" class="btn text-danger">Cancel</router-link>
                        <button type="submit" class="btn btn-primary " :disabled="isLoading">
                            <div v-if="isLoading">{{$route.params.id ? 'Updating' : 'Creating'}}<i class="fa fa-spinner fa-spin"></i></div>
                            <div v-else>{{$route.params.id ? 'Update' : 'Create'}}</div>
                        </button>
                    </div>
                </form>
            </ValidationObserver>
            <!-- <json-viewer :value="model"></json-viewer> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            model: {
                title: '',
                description: '',
                price: '',
                discountPrice: null,
                reactivationPrice: '',
                reactivationDiscountPrice: null,
                items: [
                    {
                        type: '',
                        id: ""
                    }
                ],
                polyClinicId: ""
            },
            isLoading: false,
            errors: null,
            option: {
                questionnaires: [],
                treatments: []
            },
            polyclinic: [],
            uniqueError: {
				name: false
			},
            selectedPolyclinic: "",
            selectedContents: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/flows/' + _.$route.params.id)
                .then(resp => {
                    let data = resp.data.data
                    let items = []
                    for (let index = 0; index < data.items.length; index++) {
                        items.push({
                            id: data.items[index].id,
                            type: data.items[index].flow_items.itemType
                        })
                    }

                    _.model = {
                        title: data.title,
                        description: data.description,
                        price: data.price,
                        discountPrice: data.discountPrice,
                        reactivationPrice: data.reactivationPrice,
                        reactivationDiscountPrice: data.reactivationDiscountPrice,
                        items: items,
                        polyClinicId: data.polyClinicId
                    }

                    _.selectedPolyclinic = data.polyClinic
                    _.selectedContents = data.items
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getQuestionnaire() {
            let _ = this
            _.axios.get('/questionnaires')
                .then(resp => {
                    _.option.questionnaires = resp.data.data.rows
                    _.getTreatment()
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getTreatment() {
            let _ = this
            _.axios.get('/treatments')
                .then(resp => {
                    _.option.treatments = resp.data.data.rows
                    if (_.$route.params.id) {
                        _.get()
                    }
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.message
                    })
                    _.isLoading = false
                })
        },
        getPolyclinic() {
            let _ = this
            _.axios.get('/misc/poly-clinic')
                .then(resp => {
                    _.polyclinic = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        addContent() {
            let _ = this
            _.model.items.push({
                type: "",
                id: ""
            })

            _.selectedContents.push()
        },
        deleteContent(x) {
            let _ = this
            _.model.items.splice(x, 1);
            _.selectedContents.splice(x, 1);
        },
        async save() {
            let _ = this
            const payload = {
                ..._.model,
                discountPrice: _.model.price == 0 || _.model.discountPrice === '' ? null : _.model.discountPrice,
                reactivationDiscountPrice: _.model.reactivationPrice == 0 || _.model.reactivationDiscountPrice === '' ? null : _.model.reactivationDiscountPrice,
            };
            const isValid = await _.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                if (!_.$route.params.id) {
                    _.axios.post('/flows', payload)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/flows')
                        })
                        .catch(err => {
                            _.errors = err.response.data.data
                            _.isLoading = false
                        })     
                } 
                else {
                    _.axios.put('/flows/' + _.$route.params.id, payload)
                        .then(resp => {
                            _.$notify({
                                type: 'success',
                                title: 'Success!',
                                text: resp.data.message
                            })
                            _.$router.push('/flows')
                        })
                        .catch(err => {
                            _.errors = err.response.data.data
                            _.isLoading = false
                        })   
                }  
            }
        },
        check( key )
		{
			let _ = this
			let request = {
                id: _.$route.params.id ? parseInt(_.$route.params.id) : null,
                title: key
            }
			
			_.axios.post('/flows/check', request)
				.then( resp => {
					if (!resp.data.success) {
                        _.uniqueError.name = true
					} else {
                        _.uniqueError.name = false
					}
				})
		},
        numberOnly(event) {
            if (event.keyCode < 48 || event.keyCode > 57) {
                event.preventDefault();
            }
        }
    },
    mounted() {
        let _ = this
        _.getQuestionnaire()
        _.getPolyclinic()
    },
    watch: {
		'model.title': function(newValue) {
			this.model.title = newValue.toUpperCase()
			if (newValue != '') {
				this.check( newValue )
			}		
		}
    }
}
</script>