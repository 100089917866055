<template>
    <div class="auth-page">
        <div class="full-img">
            <img src="/assets/img/vuexy-login-bg.jpg" class="img-fluid" alt="">
        </div>
        <ValidationObserver ref="observer">
            <div class="login-form">
                <div class="form-logo">
                    <img src='/assets/img/logo-diri-x4.png' class="img-fluid" alt="">
                </div>

                <p class="mb-0 f-18 font-600">Halo,</p>
                <p class="text-blue font-500 f-18">Welcome to Diri Admin</p>

                <form @submit.prevent="login()" class="mt-2">
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                        <div class="col p-0">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <input type="text" id="name" class="form-control" required v-model="model.email" placeholder="Input Email">
                                <label class="form-control-placeholder" for="name">EMAIL</label>
                                <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                        <div class="col p-0">
                            <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                <input :type="showPassword ? 'text' : 'password'" id="password" class="form-control border-right-0" required v-model="model.password" placeholder="Input Password">
                                <label class="form-control-placeholder" for="password">PASSWORD</label>
                                <span class="input-group-append">
                                    <div class="input-group-text">
                                        <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue">
                                            <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                        </a>
                                    </div>
                                </span>
                            </div>
                            <small class="val-error" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>

                    <small class="float-right mt-1"><router-link to="/forgot-password" class="text-primary">Forgot Password?</router-link></small>
                    <small class="float-left mt-1"><input type="checkbox"> Remember Me</small>

                    <button type="submit" class="btn btn-primary waves-effect waves-light w-100 mt-2" :disabled="isLoading">
                        <div v-if="isLoading">Signing In <i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Sign In</div>
                    </button>

                    <div class="text-center mt-1" v-if="!isValid">
                        <section v-if="errors.type == 422">
                            <div v-for="(v, k) in errors.message" :key="k">
                                <p class="mb-0 val-error">{{v.message}}</p>
                            </div>
                        </section>
                        <section v-else-if="errors.type == 400">
                            <p class="mb-0 val-error">{{errors.message}}</p>
                        </section>
                    </div>
                </form>                
            </div>            
        </ValidationObserver>  
    </div> 
</template>

<script>
import { HIDDEN_MENUS } from '@/constant/menu';
import cookies from 'js-cookie';

export default {
    data() {
        return {
            model: {
                isAdmin: true
            },
            isValid: true,
            isLoading: false,
            showPassword: false,

            errors: {
                type: null,
                message: []
            }
        }
    },
    mounted() {
        let accessToken = cookies.get('accessToken')
        if (accessToken) {
            this.axios.get('/auth/me')
                .then(resp => {
                    const filteredMenus = resp.data.data.admin.role.menus
                        .filter(menu => !HIDDEN_MENUS.includes(menu.link))
                        .map(menu => ({
                            ...menu,
                            children: menu.children.filter(child => !HIDDEN_MENUS.includes(child.link))
                        }));
                    if (filteredMenus.length > 0) {
                        if(filteredMenus[0].children?.length > 0){
                            window.location.assign(filteredMenus[0].children[0].link);
                        } else{
                            window.location.assign(filteredMenus[0].link);
                        }
                    } else {
                        this.$store.dispatch('logout');
                        window.location.assign('/login');
                    }
                })
                .catch(() => {

                })

        }
    },
	methods: {
		async login() {
            let _ = this  
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.post('/auth/login', _.model)
                    .then(resp=>{
                        _.$store.dispatch('login', resp.data.data)
                        const filteredMenus = resp.data.data.account.admin.role.menus
                            .filter(menu => !HIDDEN_MENUS.includes(menu.link))
                            .map(menu => ({
                                ...menu,
                                children: menu.children.filter(child => !HIDDEN_MENUS.includes(child.link))
                            }));
                        if (filteredMenus.length > 0) {
                            if(filteredMenus[0].children?.length > 0){
                                window.location.assign(filteredMenus[0].children[0].link);
                            } else{
                                window.location.assign(filteredMenus[0].link);
                            }
                        } else {
                            this.$store.dispatch('logout');
                            window.location.assign('/login');
                        }
                    })
                    .catch(err=>{
                        _.errors.type = err.response.status
                        if (err.response.status == 422) {
                            _.errors.message = err.response.data.data
                        } else if (err.response.status == 400) {
                            _.errors.message = err.response.data.message
                        }
                        _.isValid = false
                        _.isLoading = false
                    })
            }
		}
	},
}
</script>