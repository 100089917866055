import cookies from 'js-cookie';

export default {
    UPDATE_USER(state, payload) {
    	state.account = payload.account

    	cookies.set('accessToken', payload.accessToken)
    	cookies.set('refreshToken', payload.refreshToken)
    	localStorage.setItem('account', JSON.stringify(payload.account))
    },
    LOGOUT_USER(state) {
    	state.account = null
    	cookies.remove('accessToken')
    	cookies.remove('refreshToken')
        localStorage.removeItem('accessToken') // TODO: will be removed later
		localStorage.removeItem('account')
    },
    SET_CONTENT_DATA(state, payload) {
        let content = state.content
        if (!content)  {
            let title = ''
            let slug = ''
            let pageTypeId = ''

            state.content = {
                title,
                slug,
                pageTypeId
            }
        } else {
            state.content.title = payload.title || (content.title || '')
            state.content.slug = payload.slug || (content.slug || '')
            state.content.pageTypeId = payload.pageTypeId || (content.pageTypeId || '')
        }
    },
    SET_CONTENT_LANDING_DATA(state, payload) {
        let content = state.content
        if (!content)  {
            let title = ''
            let slug = ''
            let pageTypeId = ''
            let description = ''
            let lists = []

            state.content = {
                title,
                slug,
                pageTypeId,
                option: {
                    description,
                    lists
                }
            }
        } else {
            state.content.title = payload.title || (content.title || '')
            state.content.slug = payload.slug || (content.slug || '')
            state.content.pageTypeId = payload.pageTypeId || (content.pageTypeId || '')

            if ( payload.pageOption && content.pageOption ) {

                if ( payload.pageOption.lists && content.pageOption.lists ) {
                    // console.log('LISTS 2 IF')
                    let plist = payload.pageOption.lists 
                    let rawList = { ...plist }
                    const keys = Object.keys(rawList)
                    let lists = keys.map(i => rawList[i])
                    state.content.pageOption.lists = lists
                }
            }
        }
    },
    SET_CONTENT_TRANSITION_DATA(state, payload) {
        let content = state.content

        if ( payload.pageOption.image !== undefined  ) { 
            state.content.pageOption.image = ( payload.pageOption.image === null ? (content.pageOption.image || '') : payload.pageOption.image )
        }
    },
    SET_CONTENT_IMG_OPT_DATA(state, payload) {
        let content = state.content

        if ( payload.pageOption && content.pageOption ) {

            if ( payload.pageOption.choices && content.pageOption.choices ) {
                // console.log('choices 2 IF')
                let plist = payload.pageOption.choices 
                let rawList = { ...plist }
                const keys = Object.keys(rawList)
                let choices = keys.map(i => rawList[i])
                state.content.pageOption.choices = choices
            }
        }
    },
    SET_CONTENT_ESSAY_DATA(state, payload) {
        let content = state.content

        if ( payload.pageOption.essay ) {
            if ( payload.pageOption.essay.optional === null  ) {
                state.content.pageOption.essay.optional = content.pageOption.essay.optional
                // console.log('IF')
            } else if ( payload.pageOption.essay.optional === undefined ) {
                // console.log('UNDEFINED')
                state.content.pageOption.essay.optional = content.pageOption.essay.optional
            } else {
                state.content.pageOption.essay.optional = payload.pageOption.essay.optional
                // console.log('ELSE')
                // console.log(payload.pageOption.essay.optional)
            }

            if ( payload.pageOption.essay.placeholder !== undefined ) {
                state.content.pageOption.essay.placeholder = ( payload.pageOption.essay.placeholder === null ? (content.pageOption.essay.placeholder || '') : payload.pageOption.essay.placeholder ) 
            }

        }
    },
    SET_CONTENT_SINGLE_ANSWER_DATA(state, payload) {
        let content = state.content

        if ( payload.pageOption && content.pageOption ) {

            if ( payload.pageOption.choices && content.pageOption.choices ) {
                // console.log('choices 2 IF')
                let plist = payload.pageOption.choices 
                let rawList = { ...plist }
                const keys = Object.keys(rawList)
                let choices = keys.map(i => rawList[i])
                state.content.pageOption.choices = choices
            }
        }
    },
    SET_CONTENT_SINGLE_ANSWER_WITH_TEXTBOX_DATA(state, payload) {
        let content = state.content

        if ( payload.pageOption && content.pageOption ) {

            if ( payload.pageOption.choices && content.pageOption.choices ) {
                // console.log('choices 2 IF')
                let plist = payload.pageOption.choices 
                let rawList = { ...plist }
                const keys = Object.keys(rawList)
                let choices = keys.map(i => rawList[i])
                state.content.pageOption.choices = choices
            }
        }
    },
    SET_CONTENT_MULTIPLE_ANSWER_DATA(state, payload) {
        let content = state.content

        if ( payload.pageOption && content.pageOption ) {

            if ( payload.pageOption.choices && content.pageOption.choices ) {
                // console.log('choices 2 IF')
                let plist = payload.pageOption.choices 
                let rawList = { ...plist }
                const keys = Object.keys(rawList)
                let choices = keys.map(i => rawList[i])
                state.content.pageOption.choices = choices
            }
        }
    },
    SET_CONTENT_UPLOAD_PHOTO_DATA(state, payload) {
        if (state.content.pageOption) {
            if (payload.pageOption && payload.pageOption.tips) {
                state.content.pageOption.tips = payload.pageOption.tips;
            }
            if (payload.pageOption && payload.pageOption.guides) {
                state.content.pageOption.guides = payload.pageOption.guides;
            }
            if (payload.pageOption && payload.pageOption.badExamples) {
                state.content.pageOption.badExamples = payload.pageOption.badExamples;
            }
            if (payload.pageOption && payload.pageOption.frames) {
                state.content.pageOption.frames = payload.pageOption.frames;
            }
        }
    },
    REMOVE_OPT_DATA(state, payload) {
        state.content.pageOption.lists = state.content.pageOption.lists.filter( (list, id) => id != payload )
    },
    ADD_OPT_DATA(state) {
        state.content.pageOption.lists.push({
            file: '',
            title: '',
            description: ''
        })
    },
    RESET_PAGE_OPTION(state, payload) {
        if (payload == 1) {
            state.content.pageOption = {
                description: '',
                lists: [
                    {
                        id: '',
                        title: '',
                        icon: '',
                        description: ''
                    }
                ]
            }
        } else if ( payload == 2 ) {
            state.content.pageOption = {
                preTitle: '',
                title: '',
                image: '',
                description: ''
            }
        } else if ( payload == 6 ) {
            state.content.pageOption = {
                preTitle: '',
                title: '',
                description: '',
                optionType: 'image',
                choices: [
                    {
                        file: '',
                        id: '',
                        title: ''
                    }
                ]
            }
        } else if ( payload == 7 ) {
            state.content.pageOption = {
                preTitle: '',
                title: '',
                description: '',
                essay: {
                    optional: true,
                    placeholder: ''
                }
            }
        } else if ( payload == 3 ) {
            state.content.pageOption = {
                preTitle: '',
                title: '',
                description: '',
                optionType: 'radio',
                isMultiple: false,
                choices: [
                    {
                        id: '',
                        label: ''
                    }
                ]
            }
        } else if ( payload == 4 ) {
            state.content.pageOption = {
                preTitle: '',
                title: '',
                description: '',
                optionType: 'radio',
                isMultiple: false,
                choices: [
                    {
                        id: '',
                        label: '',
                        textbox: {
                            placeholder: '',
                            title: ''
                        }
                    }
                ]
            }
        } else if ( payload == 5 ) {
            state.content.pageOption = {
                preTitle: '',
                title: '',
                description: '',
                optionType: 'checkbox',
                isMultiple: true,
                choices: [
                    {
                        id: '',
                        label: ''
                    }
                ]
            }
        } else if (payload === 8) {
            state.content.pageOption = {
                title: '',
                description: '',
                tips: [],
                guides: [],
                frames: [],
            };
        }
    },
    SET_EDIT_CONTENT_DATA(state, payload) {
        state.contentEdit = payload
    },
    SET_PRETITLE(state, payload) {
        let content = state.content
        if (payload.pageOption.preTitle !== undefined) {
            state.content.pageOption.preTitle = (payload.pageOption.preTitle === null ? (content.pageOption.preTitle || '') : payload.pageOption.preTitle)
        }
    },
    SET_TITLE(state, payload) {
        let content = state.content
        if (payload.pageOption.title !== undefined) {
            state.content.pageOption.title = (payload.pageOption.title === null ? (content.pageOption.title || '') : payload.pageOption.title)
        }
    },
    SET_DESCRIPTION(state, payload) {
        let content = state.content
        if (payload.pageOption.description !== undefined) {
            state.content.pageOption.description = (payload.pageOption.description === null ? (content.pageOption.description || '') : payload.pageOption.description)
        }
    }
}