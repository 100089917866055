export const HIDDEN_MENUS = [
  '/services',
  '/packages',
  '/sitemaps',
  '/daftar-pelanggan',
  '/daftar-transaksi',
  '/apotek-dan-pengiriman',
  '/konsultasi-dan-resep',
  '/clinical-user-setting',
  '/satisfaction-survey',
  // DAT v2 initial release: hide these menus below:
  '/diri-clinic',
  '/admins',
  '/role-permissions',
  '/store',
  '/testimony-library',
  '/testimony-video-library',
  '/testimony-page',
  '/medical-advisor-expertise',
  '/medical-advisor-library',
  '/advisor-page',
  '/article-category',
  '/article-tag',
  '/article-library',
  '/article-page',
  '/faq-category',
  '/faq-tag',
  '/faq-library',
  '/faq-page',
  '/tools'
];